<template>
  <div>
    <b-card>
      <b-breadcrumb :items="breadCrumbItems"></b-breadcrumb>
    </b-card>

    <b-card :title="title">
      <form-wizard ref="eventFormWizard" color="#577BFA" :title="null" :subtitle="null" shape="square"
        back-button-text="Previous" class="mb-1" @on-complete="promptPublish">

        <tab-content title="Basic Information" :before-change="validateBasicInfo">
          <event-basic-information ref="basicInfoTab" :event="event" :projectOptions="projects"
            :sourceOptions="sourceChannels" :tierOptions="tiers" :referrerOptions="referrers"
            :eventTypeOptions="eventTypes" :eventCategoryOptions="eventCategories"
            :dynamicFieldOptions="dynamicFieldOptions" />
        </tab-content>
        <tab-content title="Settings" :before-change="validateSettings">
          <event-settings ref="settingsTab" :event="event" />
        </tab-content>
        <tab-content v-if="!isEdit" title="Preview">
          <event-preview-tab ref="previewTab" :event="event" :source-channels="sourceChannels" :projects="projects" />
        </tab-content>

        <!-- Hide draft button. To be reopened when ready to implement
        <template #custom-buttons-right="{ activeTabIndex }">
          <b-button class="mr-2" variant="outline-primary" @click="saveDraft(activeTabIndex)">Save as Draft</b-button>
        </template> -->
        <template #prev>
          <b-button variant="outline-primary">
            <feather-icon icon="ChevronLeftIcon" class="mr-50" />
            <span class="align-middle">Previous</span>
          </b-button>
        </template>
        <template #next="{ activeTabIndex }">
          <b-button variant="outline-primary">
            <span class="align-middle">{{ nextBtnTxt(activeTabIndex) }}</span>
            <feather-icon v-if="activeTabIndex == 0" icon="ChevronRightIcon" class="ml-50" />
          </b-button>
        </template>
        <template #finish>
          <b-button variant="primary">{{ finishBtnTxt }}</b-button>
        </template>
        <template #custom-buttons-right="{ activeTabIndex }">
          <b-button v-if="showSaveBtn(activeTabIndex)" class="mr-2" variant="primary" @click="validateAndSave">Save
          </b-button>
        </template>
      </form-wizard>
      <b-overlay :show="showOverlay" variant="white" spinner-variant="primary" no-wrap></b-overlay>
    </b-card>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BBreadcrumb,
  BCard,
  BButton,
  BOverlay,
} from 'bootstrap-vue'
import EventBasicInformation from './EventBasicInformation.vue'
import EventSettings from './EventSettings.vue'
import EventPreviewTab from './EventPreviewTab.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { is } from 'vee-validate/dist/rules'

export default {
  components: {
    FormWizard,
    TabContent,
    ToastificationContent,
    BBreadcrumb,
    BCard,
    EventBasicInformation,
    BButton,
    EventSettings,
    EventPreviewTab,
    FeatherIcon,
    BOverlay,
  },
  data() {
    return {
      title: "Create New Event",
      event: {
        name: '',
        location: '',
        project_id: '',
        source_channel_id: '',
        channel_country: '',
        exclusiveTiers: [],
        thumbnail: null,
        thumbnailUrl: '',
        start_date: '',
        end_date: '',
        tagging: '',
        type: '',
        category: '',
        budget: '',
        no_of_pax: '',
        is_registrable: '',
        has_survey: '',
        at_sales_gallery: '',
        is_public: '',
        is_featured: '',
        edm_english: null,
        edmEnglishUrl: '',
        edm_chinese_simplified: null,
        edmChineseSimplifiedUrl: '',
        edm_chinese_traditional: null,
        edmChineseTraditionalUrl: '',
        short_description: '',
        description: '',
        invitees: [],
        dynamicFields: [],
      },
      sourceChannels: [],
      projects: [],
      tiers: [],
      referrers: [],
      eventTypes: [
        {
          text: 'General',
          value: 'General',
          icon: "general"
        },
        {
          text: 'Private',
          value: 'Private',
          icon: "private"
        }
      ],
      eventCategories: [
        {
          text: 'RSVP',
          value: 'RSVP',
          icon: "rsvp"
        },
        {
          text: 'General Registration',
          value: 'General Registration',
          icon: "general-registration",
        }
      ],
      breadCrumbItems: [
        {
          text: 'Event Listing',
          to: { name: 'event.index' }
        },
        {
          text: 'Create New Event',
          active: true
        }
      ],
      isEdit: false,
      showOverlay: false,
      dynamicFieldOptions: [],
    }
  },
  computed: {
    finishBtnTxt() {
      return this.isEdit ? 'Save' : 'Publish';
    }
  },
  async created() {
    const eventId = this.$route.params.eventId;
    this.isEdit = eventId != undefined;
    await this.getData();

    if (this.isEdit) {
      //Enable all the tabs if is edit flow
      this.$refs.eventFormWizard.activateAll();
    }
  },
  methods: {
    async getData() {
      const projectRes = await this.$http.get('/api/dropdown/project');
      const sourceRes = await this.$http.get('/api/dropdown/source-channel');
      const tierRes = await this.$http.get('/api/dropdown/tier');
      const referrerRes = await this.$http.get('/api/dropdown/referrer');
      const dynamicDataFieldRes = await this.$http.get('/api/dropdown/dynamic-data-field');
      this.projects = projectRes.data.data;
      this.sourceChannels = sourceRes.data.data;
      this.tiers = tierRes.data.data;
      this.referrers = referrerRes.data.data;
      this.dynamicFieldOptions = dynamicDataFieldRes.data.data.map(field => this.mapDynamicDataFields(field));

      if (this.isEdit) {
        const eventRes = await this.$http.get(`api/event/${this.$route.params.eventId}`);
        var mData = eventRes.data.data;
        this.mapToEvent(mData);
        this.breadCrumbItems[1].text = this.event.name;
        this.title = this.event.name;
        this.$refs.basicInfoTab.prefillEventDetails();
        this.$refs.settingsTab.prefillEventDetails();
      }
    },
    async validateBasicInfo() {
      const result = await this.$refs.basicInfoTab.validate();
      if (result) {
        const filledEvent = this.$refs.basicInfoTab.getFilledEventDetails();
        this.event.name = filledEvent.title
        this.event.location = filledEvent.location
        this.event.project_id = filledEvent.project
        this.event.source_channel_id = filledEvent.source
        this.event.channel_country = filledEvent.channel_country
        this.event.exclusiveTiers = filledEvent.exclusiveTiers
        this.event.thumbnail = filledEvent.thumbnail
        this.event.thumbnailUrl = filledEvent.thumbnailUrl
        this.event.start_date = filledEvent.start_date
        this.event.end_date = filledEvent.end_date
        this.event.tagging = filledEvent.tag
        this.event.type = filledEvent.type
        this.event.category = filledEvent.category
        this.event.invitees = filledEvent.invitees
        this.event.dynamicFields = filledEvent.dynamicFields
        this.scrollToTop();
      }
      return result;
    },
    async validateSettings() {
      const result = await this.$refs.settingsTab.validate();
      if (result) {
        const filledEvent = this.$refs.settingsTab.getFilledEventDetails();
        this.event.budget = filledEvent.budget;
        this.event.no_of_pax = filledEvent.maxPax;
        this.event.is_registrable = filledEvent.openForRegistration;
        this.event.has_survey = filledEvent.conductSurvey;
        this.event.at_sales_gallery = filledEvent.atSalesGallery;
        this.event.is_public = filledEvent.displayInApp;
        this.event.is_featured = filledEvent.displayAtHomePage;
        this.event.edm_english = filledEvent.edm_english;
        this.event.edmEnglishUrl = filledEvent.edmEnglishUrl;
        this.event.edm_chinese_simplified = filledEvent.edm_chinese_simplified;
        this.event.edmChineseSimplifiedUrl = filledEvent.edmChineseSimplifiedUrl;
        this.event.edm_chinese_traditional = filledEvent.edm_chinese_traditional;
        this.event.edmChineseTraditionalUrl = filledEvent.edmChineseTraditionalUrl;
        this.event.short_description = filledEvent.shortDesc;
        this.event.description = filledEvent.longDesc;
        this.scrollToTop();
      }
      return result;
    },
    saveDraft(tabIndex) {
      if (tabIndex == 0) {
        this.$refs.basicInfoTab.validate()
          .then(success => {
            console.log(success);
            if (success) {
              this.promptSaveDraft();
            }
          })
          .catch(error => {
            console.log("error");
            this.promptFillAllFields();
          });
      } else if (tabIndex == 1) {
        this.$refs.settingsTab.validate()
          .then(success => {
            if (success) {
              this.promptSaveDraft();
            }
          })
          .catch(error => {
            this.promptFillAllFields();
          });
      }
    },
    promptFillAllFields() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Please fill in all compulsory fields',
          icon: 'EditIcon',
          variant: 'danger',
        },
      });
    },
    promptSaveDraft() {
      this.$swal({
        text: "Are you sure you want to save this event as draft?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Save as Draft',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-danger',
        },
        buttonsStyling: false,
        reverseButtons: true,
      }).then(result => {
        if (result.value) {
          //TODO Call API to save draft
          this.showDraftSuccessDialog();
        }
      });
    },
    promptPublish() {
      this.$swal({
        text: this.isEdit ? "Are you sure you want to save the changes?" : "Are you sure you want to publish the event?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.isEdit ? 'Save' : 'Publish',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-danger',
        },
        buttonsStyling: false,
        reverseButtons: true,
      }).then(result => {
        if (result.value) {
          this.showOverlay = true;
          if (this.isEdit) {
            this.editEvent();
          } else {
            this.createEvent();
          }
        }
      });
    },
    generateEventFormData() {
      var formData = new FormData();
      for (var key in this.event) {
        const value = this.event[key];
        if (value || value === false) {
          if (key === 'exclusiveTiers') {
            for (var index in value) {
              const tier = value[index];
              formData.append('exclusiveTiers[]', tier.id);
            }
          } else if (key === 'invitees') {
            for (var index in value) {
              const referrer = value[index];
              formData.append('invitees[]', referrer.id);
            }
          } else if (key === 'dynamicFields') {
            for (var index in value) {
              const field = value[index];
              formData.append(`data_fields[${index}][name]`, field.selectedOption);
              formData.append(`data_fields[${index}][is_required]`, field.required ? 1 : 0);
              formData.append(`data_fields[${index}][position]`, parseInt(index)+1);
            }
          } else {
            if (value === true || value === false) {
              formData.append(key, value ? 1 : 0);
            } else {
              formData.append(key, value);
            }
          }
        }
      }
      return formData;
    },
    createEvent() {
      this.$http.post('/api/event', this.generateEventFormData(), {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then((res) => {
          this.showPublishSuccessDialog();
        })
        .catch((error) => {
          this.showErrorToast(error);
        })
        .then(() => {
          this.showOverlay = false;
        });
    },
    editEvent() {
      const formData = this.generateEventFormData();
      formData.append('_method', 'PATCH');
      this.$http.post(`/api/event/${this.$route.params.eventId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then((res) => {
          this.showPublishSuccessDialog();
        })
        .catch((error) => {
          this.showErrorToast(error);
        })
        .then(() => {
          this.showOverlay = false;
        });
    },
    showDraftSuccessDialog() {
      this.$swal({
        icon: 'success',
        text: 'Event has successfully been saved as draft',
        showConfirmButton: false,
        timer: 3000
      });
    },
    showPublishSuccessDialog() {
      this.$swal({
        icon: 'success',
        text: this.isEdit ? 'Changes have been successfully saved' : 'Event has been successfully published',
        showConfirmButton: false,
        timer: 3000
      }).then(e => {
        this.$router.push({ name: "event.index" });
      });
    },
    nextBtnTxt(index) {
      const tabCount = this.$refs.eventFormWizard.tabs.length;
      var nextTxt = 'Next';

      if (!this.isEdit && (index === tabCount - 2)) {
        nextTxt = 'Preview';
      }
      return nextTxt;
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    showErrorToast(errorTxt) {
      this.$toast({
        component: ToastificationContent,
        props: {
          icon: 'AlertCircleIcon',
          text: errorTxt,
          variant: 'danger',
        },
      });
    },
    mapToEvent(data) {
      this.event.name = data.name;
      this.event.location = data.location;
      this.event.project_id = data.project_id;
      this.event.source_channel_id = data.source_channel_id;
      this.event.channel_country = data.channel_country;
      this.event.thumbnailUrl = data.thumbnail;
      this.event.type = data.type;
      this.event.category = data.category;
      this.event.budget = data.budget;
      this.event.no_of_pax = data.no_of_pax;
      this.event.is_registrable = data.is_registrable;
      this.event.has_survey = data.has_survey;
      this.event.is_public = data.is_public;
      this.event.is_featured = data.is_featured;
      this.event.short_description = data.short_description;
      this.event.description = data.description;
      this.event.start_date = data.start_date;
      this.event.end_date = data.end_date;
      this.event.at_sales_gallery = data.at_sales_gallery;
      this.event.edmEnglishUrl = data.edms?.find(edm => edm.language === 'en')?.edm_path;
      this.event.edmChineseSimplifiedUrl = data.edms?.find(edm => edm.language === 'zh-CN')?.edm_path;
      this.event.edmChineseTraditionalUrl = data.edms?.find(edm => edm.language === 'zh-TW')?.edm_path;
      this.event.exclusiveTiers = data.tiers;
      this.event.tagging = data.tagging;
      this.event.invitees = data.invitees;
      this.event.dynamicFields = data.data_fields;
    },
    showSaveBtn(tabIndex) {
      return this.isEdit && tabIndex < this.$refs.eventFormWizard.tabs.length - 1;
    },
    async validateAndSave() {
      const validBasicInfo = await this.validateBasicInfo();
      const validSettings = await this.validateSettings();
      if (validBasicInfo && validSettings) {
        this.promptPublish();
      }
    },
    mapDynamicDataFields(data) {
      return {
        id: data.id,
        name: data.name,
        label: data.placeholder,
      };
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
</style>
