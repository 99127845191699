<template>
    <div class="flex mb-2">
        <div class="w-20">
            <feather-icon icon="MenuIcon" />
        </div>
        <div class="mx-2 field-box">
            <validation-provider class="absolute width-500 center-vertical" #default="{ errors }"
                name="Form's Data Field" rules="required">
                <b-form-select required v-model="selectedField" :options="fieldOptions" text-field="label"
                    value-field="name" v-cloak @change="(value) => $emit('change', value)">
                </b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
        </div>
        <div>
            {{ requiredSign }}
        </div>
        <div>
            <b-dropdown variant="link" toggle-class="text-decoration-none p-1" no-caret>
                <template #button-content>
                    <feather-icon icon="MoreVerticalIcon" size="16" class="text-body" />
                </template>
                <b-dropdown-item @click="$emit('required', !isRequired)">
                    <span>{{ requiredOptionalTxt }}</span>
                </b-dropdown-item>
                <b-dropdown-item v-b-modal.rsvp-link @click="$emit('remove')">
                    <span>Delete</span>
                </b-dropdown-item>
            </b-dropdown>
        </div>
    </div>
</template>

<script>
import { BRow, BCol, BFormSelect, BButton, BDropdown, BDropdownItem } from 'bootstrap-vue';
import { ValidationProvider } from 'vee-validate';

export default {
    components: {
        BRow,
        BCol,
        BFormSelect,
        BButton,
        BDropdown,
        BDropdownItem,
        ValidationProvider,
    },
    props: {
        fieldOptions: {
            type: Array,
            default: []
        },
        selectedOption: {
            type: String,
            default: ''
        },
        isRequired: {
            type: Boolean,
            default: false
        }
    },
    emits: ['remove', 'required', 'change'],
    data() {
        return {
            selectedField: this.selectedOption
        }
    },
    computed: {
        requiredOptionalTxt() {
            return this.isRequired ? 'Make Optional' : 'Make Required';
        },
        requiredSign() {
            return this.isRequired ? '*' : '';
        }
    }
}
</script>

<style>
.flex {
    display: flex;
    align-items: center;
}

.field-box {
    min-width: 500px;
    width: 500px;
    position: relative;
    height: 50px;
}

.absolute {
    position: absolute;
}

.width-500 {
    min-width: 500px;
    width: 500px;
}

.center-vertical {
    top: 50%;
    transform: translateY(-50%);
}

.left-15 {
    left: 15px;
}
</style>