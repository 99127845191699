<template>
    <validation-observer ref="basicInfoRules" tag="form">
        <b-row>
            <b-col>
                <h4>Basic Information</h4>
            </b-col>
        </b-row>
        <b-row class="mt-2">
            <b-col class="mb-2" cols="12">
                <h5>GENERAL</h5>
            </b-col>
            <b-col cols="6">
                <b-form-group label="Event Title" label-for="eventTitle">
                    <validation-provider #default="{ errors }" name="Event Title" rules="required">
                        <b-form-input v-model="eventTitle" id="eventTitle" placeholder="Enter Event Title"
                            :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
            </b-col>
            <b-col cols="6">
                <b-form-group label="Location" label-for="location">
                    <validation-provider #default="{ errors }" name="Location" rules="required">
                        <b-form-input v-model="location" id="location" placeholder="Enter Location"
                            :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
            </b-col>
            <b-col cols="6">
                <b-form-group label="Project" label-for="project">
                    <validation-provider #default="{ errors }" name="Project" rules="required">
                        <b-form-select v-model="selectedProject" :options="projectOptions" value-field="id"
                            text-field="name" :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
            </b-col>
            <b-col cols="6">
                <b-form-group label="Source Channel" label-for="sourceChannel">
                    <validation-provider #default="{ errors }" name="Source Channel" rules="required">
                        <b-form-select v-model="selectedSource" :options="sourceOptions" value-field="id"
                            text-field="name" :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Channel Country" label-for="country">
                <validation-provider #default="{ errors }" name="Country" rules="required">
                  <b-form-select v-model="selectedCountry" :options="countryOptions"
                                 :state="errors.length > 0 ? false : null" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
                <b-form-group label="Exclusive To" label-for="exclusiveTo">
                    <validation-provider #default="{ errors }" name="Exclusive To" rules="required">
                        <vue-select v-model="selectedTiers" :options="tierOptions" multiple
                            :state="errors.length > 0 ? false : null" label="title" />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
            </b-col>
        </b-row>

        <hr class="my-2" />

        <b-row no-gutters>
            <b-col cols="12">
                <h5>IMAGE UPLOAD</h5>
            </b-col>
            <b-col cols="12" class="grey-container p-1 my-1">
                <b-row>
                    <b-col cols="3">
                        <div id="preview">
                            <img width="200" height="200" v-if="imageUrl" :src="imageUrl" />
                        </div>
                    </b-col>
                    <b-col class="ml-1" cols="7">
                        <div class="mb-1">Required image resolution 1000x1000, image size 10MB.</div>
                        <validation-provider #default="{ errors }" name="Image" :rules="imageRules">
                            <b-form-file :value="thumbnail" placeholder="Choose File"
                                drop-placeholder="Drop file here..." :state="errors.length > 0 ? false : null"
                                accept="image/*" @change="onImageChange" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>

        <hr class="my-2" />

        <b-row class="mt-2">
            <b-col class="mb-2" cols="12">
                <h5>EVENT</h5>
            </b-col>
            <b-col cols="6">
                <b-form-group label="Event Date" label-for="eventDate">
                    <b-form-datepicker v-model="eventDate"
                        :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit' }" locale="en-GB" />
                </b-form-group>
            </b-col>
            <b-col cols="6">
                <b-form-group label="Start Time" label-for="startTime">
                    <b-form-timepicker v-model="startTime" hour12 hourCycle="h12" locale="en" />
                </b-form-group>
            </b-col>
            <b-col cols="6">
                <b-form-group label="Event Tagging" label-for="eventTagging">
                    <validation-provider #default="{ errors }" name="Event Tagging" rules="required">
                        <b-form-select v-model="eventTag" :options="eventTagOptions"
                            :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
            </b-col>
            <b-col cols="6">
                <b-form-group label="End Time" label-for="endTime">
                    <b-form-timepicker v-model="endTime" hour12 hourCycle="h12" locale="en" />
                </b-form-group>
            </b-col>
            <b-col cols="6">
                <b-form-group label="Event Type" label-for="eventType">
                    <validation-provider #default="{ errors }" name="Event Type" rules="required">
                        <box-radio-button :options="eventTypeOptions" :value="selectedEventType"
                            @select="(value) => selectedEventType = value" />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
            </b-col>
            <b-col cols="6">
                <b-form-group label="Category" label-for="category">
                    <validation-provider #default="{ errors }" name="Category" rules="required">
                        <box-radio-button :value="selectedCategory" :options="eventCategoryOptions"
                            @select="(value) => selectedCategory = value" />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row v-if="isPrivate">
            <b-col cols="6">
                <b-form-group label="Select KORA member" label-for="guests">
                    <vue-select v-model="privateGuests" :options="referrerOptions" multiple label="name">
                        <template #option="{ name, phone_number }">
                            {{ name }} ({{ phone_number }})
                        </template>
                        <template #selected-option="{ name, phone_number }">
                            {{ name }} ({{ phone_number }})
                        </template>
                    </vue-select>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row v-if="isGeneralRegistration" no-gutters>
            <b-col cols="12" class="grey-container p-2 my-1">
                <b-row class="mx-2">
                    <b-col cols="12">
                        <h5>
                            Form's Data Field Configuration
                        </h5>
                    </b-col>
                </b-row>
                <draggable v-if="dynamicFields.length > 0" v-model="dynamicFields" tag="ul"
                    class="list-group list-group-flush cursor-move mt-2">
                    <DynamicFormField v-for="(field, index) in dynamicFields" :key="field.id"
                        :fieldOptions="dynamicFieldOptions" :selectedOption="field.selectedOption"
                        :isRequired="field.required" @required="(required) => onFieldRequiredChange(field, required)"
                        @remove="onFieldRemove(index)" @change="(value) => onFieldChange(field, value)" />
                </draggable>
                <b-row class="mx-2">
                    <b-col cols="12" class="mt-2">
                        <b-button variant="primary" @click="onFieldAdd">
                            <feather-icon icon="PlusIcon" class="mr-25" />
                            <span>Add Data Field</span>
                        </b-button>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>

    </validation-observer>
</template>

<script>
import { TabContent } from 'vue-form-wizard'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { BRow, BCol, BFormGroup, BFormInput, BFormSelect, BFormFile, BFormRadio, BFormRadioGroup, BFormDatepicker, BFormTimepicker, BButton } from 'bootstrap-vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { required } from '@validations'
import { VueSelect } from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import BoxRadioButton from './components/BoxRadioButton.vue'
import draggable from 'vuedraggable'
import DynamicFormField from './components/DynamicFormField.vue'

export default {
    components: {
        TabContent,
        ValidationObserver,
        ValidationProvider,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BButton,
        required,
        VueSelect,
        BFormFile,
        flatPickr,
        BFormRadio,
        BFormRadioGroup,
        ToastificationContent,
        BFormDatepicker,
        BFormTimepicker,
        BoxRadioButton,
        draggable,
        DynamicFormField
    },
    props: {
        event: {
            type: Object
        },
        projectOptions: {
            type: Array
        },
        sourceOptions: {
            type: Array
        },
        tierOptions: {
            type: Array
        },
        referrerOptions: {
            type: Array
        },
        eventTypeOptions: {
            type: Array
        },
        eventCategoryOptions: {
            type: Array
        },
        dynamicFieldOptions: {
            type: Array
        }
    },
    data() {
        return {
            eventTitle: '',
            location: '',
            selectedProject: '',
            selectedSource: '',
            selectedCountry: '',
            countryOptions: [
              'Malaysia',
              'China',
              'Hong Kong',
              'Taiwan',
              'Japan',
              'Singapore',
              'Korea',
              'Middle East',
            ],
            selectedTiers: [],
            thumbnailUrl: '',
            thumbnail: null,
            eventDate: null,
            startTime: null,
            endTime: null,
            eventTagOptions: [
                'Member Get Member',
                'Private Event',
                'Member Engagement',
                'KSK Community Event',
                'KORA Sales Event',
            ],
            eventTag: '',
            selectedEventType: 'General',
            selectedCategory: 'RSVP',
            privateGuests: [],
            dynamicFields: [],
            dynamicFieldId: 1,
        }
    },
    computed: {
        imageUrl() {
            if (this.thumbnail) {
                return URL.createObjectURL(this.thumbnail);
            } else if (this.thumbnailUrl) {
                return this.thumbnailUrl;
            }
        },
        isPrivate() {
            return this.selectedEventType === 'Private';
        },
        isGeneralRegistration() {
            return this.selectedCategory === 'General Registration';
        },
        imageRules() {
            if (this.thumbnailUrl) {
                return '';
            } else {
                return 'required';
            }
        }
    },
    methods: {
        validate() {
            return new Promise((resolve, reject) => {
                this.$refs.basicInfoRules.validate().then(success => {
                    if (success) {
                        resolve(true)
                    } else {
                        reject()
                    }
                })
            })
        },
        onImageChange(e) {
            const newFile = e.target.files[0];
            if (newFile) {
                if (newFile.size > (1024 * 1000 * 10)) {
                    this.thumbnail = null;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'File size is too large. Please select a file less than 10MB.',
                            icon: 'EditIcon',
                            variant: 'danger',
                        },
                    });
                } else {
                    this.thumbnail = newFile;
                }
            }
        },
        getFilledEventDetails() {
            var eventInfo = {};
            if (this.event) {
                eventInfo = this.event;
            }
            eventInfo.title = this.eventTitle;
            eventInfo.location = this.location;
            eventInfo.project = this.selectedProject;
            eventInfo.source = this.selectedSource;
            eventInfo.channel_country = this.selectedCountry;
            eventInfo.exclusiveTiers = this.selectedTiers;
            eventInfo.thumbnail = this.thumbnail;
            eventInfo.thumbnailUrl = this.thumbnailUrl;
            eventInfo.start_date = this.eventDate && this.startTime ? `${this.eventDate} ${this.startTime}` : null;
            eventInfo.end_date = this.eventDate && this.endTime ? `${this.eventDate} ${this.endTime}` : null;
            eventInfo.tag = this.eventTag;
            eventInfo.type = this.eventTypeOptions.find(type => type.value === this.selectedEventType).value;
            eventInfo.category = this.eventCategoryOptions.find(category => category.value === this.selectedCategory).value;
            if (this.selectedEventType === 'Private') {
                eventInfo.invitees = this.privateGuests;
            }
            if (this.selectedCategory === 'General Registration') {
                eventInfo.dynamicFields = this.dynamicFields;
            }
            return eventInfo;
        },
        prefillEventDetails() {
            this.eventTitle = this.event.name;
            this.location = this.event.location;
            this.selectedProject = this.projectOptions.find(project => project.id === this.event.project_id)?.id;
            this.selectedSource = this.sourceOptions.find(source => source.id === this.event.source_channel_id)?.id;
            this.selectedCountry = this.event.channel_country;
            this.selectedTiers = this.event.exclusiveTiers;
            this.thumbnailUrl = this.event.thumbnailUrl;
            this.thumbnail = this.event.thumbnail
            this.eventDate = this.event.start_date?.split(" ")[0];
            this.startTime = this.event.start_date?.split(" ")[1];
            this.endTime = this.event.end_date?.split(" ")[1];
            this.eventTag = this.event.tagging;
            this.selectedEventType = this.event.type;
            this.selectedCategory = this.event.category;
            this.privateGuests = this.event.invitees;

            this.dynamicFields = this.event.dynamicFields?.map((field) => {
                this.dynamicFieldId += 1;
                return { id: this.dynamicFieldId, selectedOption: field.name, required: field.is_required }
            });
        },
        onFieldAdd() {
            this.dynamicFields.push({
                id: this.dynamicFieldId,
                selectedOption: '',
                required: false,
            });
            this.dynamicFieldId += 1;
        },
        onFieldRequiredChange(field, required) {
            field.required = required;
        },
        onFieldRemove(index) {
            if (index > -1) {
                this.dynamicFields.splice(index, 1);
            }
        },
        onFieldChange(field, value) {
            field.selectedOption = value;
        }
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';

#preview {
    display: flex;
    justify-content: center;
    align-items: center;
}

#preview img {
    max-width: 200px;
    max-height: 200px;
}

.grey-container {
    /* Grey */
    background: #F7F7F7;
    /* Black / 15% */
    border: 1px solid rgba(31, 31, 31, 0.15);
    border-radius: 4px;
}
</style>