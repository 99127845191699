<template>
    <div style="display: flex;">
        <span v-for="(option, index) in options" :key="option.value" :class="{'ml-1': index > 0}">
            <BoxRadioButtonItem :optionModel="option" :isChecked="option.value === value" @select="onSelect" />
        </span>
    </div>
</template>

<script>
import BoxRadioButtonItem from './BoxRadioButtonItem.vue'

export default {
    props: {
        options: {
            type: Array
        },
        value: {
            type: String
        },
    },
    emits: ['select'],
    components: {
        BoxRadioButtonItem,
    },
    methods: {
        onSelect(event) {
            this.$emit('select', event);
        },
    },

}
</script>

<style>

</style>