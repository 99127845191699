<template>
    <validation-observer ref="settingsRules" tag="form">
        <b-row>
            <b-col>
                <h4>Settings</h4>
            </b-col>
        </b-row>
        <b-row class="mt-2">
            <b-col cols="6">
                <b-form-group label="Budget" label-for="budget">
                    <validation-provider #default="{ errors }" name="Budget" rules="required">
                        <b-input-group prepend="RM" class="input-group-merge">
                            <b-form-input type="number" v-model="budget" id="budget"
                                :state="errors.length > 0 ? false : null" />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
            </b-col>
            <b-col cols="6">
                <b-form-group label="Maximum Pax" label-for="maximumPax">
                    <validation-provider #default="{ errors }" name="Maximum Pax" rules="required">
                        <b-form-input type="number" v-model="maximumPax" id="maximumPax" placeholder="Enter Maximum Pax"
                            :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
            </b-col>
        </b-row>

        <hr class="my-2" />

        <b-row class="mt-2">
            <b-col cols="4">
                <b-form-group label="Open for Registration" label-for="registration">
                    <validation-provider #default="{ errors }" name="Registration" rules="required">
                        <b-form-radio-group v-model="openForRegistration" :options="yesNoOptions" name="registration"
                            :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
            </b-col>
            <b-col cols="4">
                <b-form-group label="Conduct Survey after Event" label-for="survey">
                    <validation-provider #default="{ errors }" name="Survey" rules="required">
                        <b-form-radio-group v-model="conductSurvey" :options="yesNoOptions" name="survey"
                            :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
            </b-col>
            <b-col cols="4">
                <b-form-group label="Event Happen at Sales Gallery" label-for="salesGallery">
                    <validation-provider #default="{ errors }" name="Sales Gallery" rules="required">
                        <b-form-radio-group v-model="atSalesGallery" :options="yesNoOptions" name="salesGallery"
                            :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row class="mt-2">
            <b-col cols="4">
                <b-form-group label="Display at KORA App" label-for="koraApp">
                    <validation-provider #default="{ errors }" name="KORA App" rules="required">
                        <b-form-radio-group v-model="displayInApp" :options="yesNoOptions" name="koraApp"
                            :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
            </b-col>
            <b-col cols="4">
                <b-form-group label="Display at Home Page" label-for="homePage">
                    <validation-provider #default="{ errors }" name="Home Page" rules="required">
                        <b-form-radio-group v-model="displayAtHomePage" :options="yesNoOptions" name="homePage"
                            :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
            </b-col>
        </b-row>

        <hr class="my-2" />

        <b-row class="mt-2">
            <b-col>
                <h5>EDM</h5>
            </b-col>
        </b-row>
        <b-row class="mt-2">
            <b-col cols="4">
                <div>
                    <b-form-group label="EDM - English" label-for="edmEnglish">
                        <b-form-file v-model="edm_english" placeholder="Choose File" drop-placeholder="Drop file here..."
                            accept="image/*" />
                    </b-form-group>
                </div>
                <div v-if="edmEnglish">
                    <img class="edm-preview" :src="edmEnglish" />
                </div>
            </b-col>
            <b-col cols="4">
                <div>
                    <b-form-group label="EDM - Simplified Chinese" label-for="edmSimplifiedChinese">
                        <b-form-file v-model="edm_chinese_simplified" placeholder="Choose File"
                            drop-placeholder="Drop file here..." accept="image/*" />
                    </b-form-group>
                </div>
                <div v-if="edmChineseSimplified">
                    <img class="edm-preview" :src="edmChineseSimplified" />
                </div>
            </b-col>
            <b-col cols="4">
                <div>
                    <b-form-group label="EDM - Traditional Chinese" label-for="edmTraditionalChinese">
                        <b-form-file v-model="edm_chinese_traditional" placeholder="Choose File"
                            drop-placeholder="Drop file here..." accept="image/*" />
                    </b-form-group>
                </div>
                <div v-if="edmChineseTraditional">
                    <img class="edm-preview" :src="edmChineseTraditional" />
                </div>
            </b-col>
        </b-row>

        <hr class="my-2" />

        <b-row class="mt-2">
            <b-col>
                <h5>DESCRIPTIONS</h5>
            </b-col>
        </b-row>
        <b-row class="mt-2">
            <b-col cols="6">
                <b-form-group label="Short Description (Max 200 characters)" label-for="shortDesc">
                    <validation-provider #default="{ errors }" name="Short Description" rules="required|max:200">
                        <b-form-textarea id="textarea-state" v-model="shortDesc" :state="!exceedLimit ? null : false"
                            rows="5" @keyup="calculateShortDesc" />
                        <small class="textarea-counter-value float-right" :class="{ 'bg-danger': exceedLimit }">
                            <span class="char-count">{{ shortDescChar }}</span> / {{ maxChar }}
                        </small>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row class="mt-2">
            <b-col cols="12">
                <b-form-group label="Long Description" label-for="longDesc">
                    <validation-provider #default="{ errors }" name="Long Description" rules="required">
                        <quill-editor v-model="longDesc" :options="snowOption" />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
            </b-col>
        </b-row>

    </validation-observer>
</template>

<script>
import { TabContent } from 'vue-form-wizard'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { BRow, BCol, BFormGroup, BFormInput, BFormSelect, BFormFile, BFormRadio, BFormRadioGroup, BInputGroup, BFormTextarea } from 'bootstrap-vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { extend } from 'vee-validate'
import { max } from 'vee-validate/dist/rules'
import { required } from '@validations'
import { VueSelect } from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'

extend('max', max);

export default {
    props: {
        event: {
            type: Object
        }
    },
    components: {
        TabContent,
        ValidationObserver,
        ValidationProvider,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormSelect,
        required,
        VueSelect,
        BFormFile,
        flatPickr,
        BFormRadio,
        BFormRadioGroup,
        ToastificationContent,
        BInputGroup,
        BFormTextarea,
        quillEditor,
    },
    data() {
        return {
            budget: '',
            maximumPax: '',
            openForRegistration: true,
            conductSurvey: true,
            atSalesGallery: true,
            displayInApp: true,
            displayAtHomePage: true,
            yesNoOptions: [
                {
                    text: "Yes",
                    value: true
                },
                {
                    text: "No",
                    value: false
                }
            ],
            edm_english: null,
            edmEnglishUrl: '',
            edm_chinese_simplified: null,
            edmChineseSimplifiedUrl: '',
            edm_chinese_traditional: null,
            edmChineseTraditionalUrl: '',
            shortDesc: '',
            shortDescChar: 0,
            maxChar: 200,
            longDesc: '',
        }
    },
    computed: {
        exceedLimit() {
            return this.shortDescChar > this.maxChar;
        },
        edmEnglish() {
            if (this.edm_english) {
                return URL.createObjectURL(this.edm_english);
            } else if (this.event.edmEnglishUrl) {
                return this.event.edmEnglishUrl;
            }
        },
        edmChineseSimplified() {
            if (this.edm_chinese_simplified) {
                return URL.createObjectURL(this.edm_chinese_simplified);
            } else if (this.event.edmChineseSimplifiedUrl) {
                return this.event.edmChineseSimplifiedUrl;
            }
        },
        edmChineseTraditional() {
            if (this.edm_chinese_traditional) {
                return URL.createObjectURL(this.edm_chinese_traditional);
            } else if (this.event.edmChineseTraditionalUrl) {
                return this.event.edmChineseTraditionalUrl;
            }
        },
    },
    methods: {
        validate() {
            return new Promise((resolve, reject) => {
                this.$refs.settingsRules.validate().then(success => {
                    if (success) {
                        resolve(true)
                    } else {
                        reject()
                    }
                })
            })
        },
        calculateShortDesc() {
            this.shortDescChar = this.shortDesc.length;
        },
        getFilledEventDetails() {
            var eventSettings = {};
            if (this.event) {
                eventSettings = this.event;
            }
            eventSettings.budget = this.budget;
            eventSettings.maxPax = this.maximumPax;
            eventSettings.openForRegistration = this.openForRegistration;
            eventSettings.conductSurvey = this.conductSurvey;
            eventSettings.atSalesGallery = this.atSalesGallery;
            eventSettings.displayInApp = this.displayInApp;
            eventSettings.displayAtHomePage = this.displayAtHomePage;
            eventSettings.edm_english = this.edm_english;
            eventSettings.edmEnglishUrl = this.edmEnglishUrl;
            eventSettings.edm_chinese_simplified = this.edm_chinese_simplified;
            eventSettings.edmChineseSimplifiedUrl = this.edmChineseSimplifiedUrl;
            eventSettings.edm_chinese_traditional = this.edm_chinese_traditional;
            eventSettings.edmChineseTraditionalUrl = this.edmChineseTraditionalUrl;
            eventSettings.shortDesc = this.shortDesc;
            eventSettings.longDesc = this.longDesc;
            return eventSettings;
        },
        prefillEventDetails() {
            this.budget = this.event.budget;
            this.maximumPax = this.event.no_of_pax;
            this.openForRegistration = this.event.is_registrable;
            this.conductSurvey = this.event.has_survey;
            this.atSalesGallery = this.event.at_sales_gallery;
            this.displayInApp = this.event.is_public;
            this.displayAtHomePage = this.event.is_featured;
            this.shortDesc = this.event.short_description;
            this.longDesc = this.event.description;
            this.edmEnglishUrl = this.event.edmEnglishUrl;
            this.edmChineseSimplifiedUrl = this.event.edmChineseSimplifiedUrl;
            this.edmChineseTraditionalUrl = this.event.edmChineseTraditionalUrl;
        }
    },
}

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.edm-preview {
    width: 100px;
    height: 100px;
}
</style>