<template>
    <span class="box" :class="{selected: isChecked, disabled: optionModel.disabled}" @click="onClick">
        <img src="@/assets/icons/checked.svg" class="check-icon"
            :style="{visibility: isChecked ? 'visible' : 'hidden'}" />
        <img class="icon" :src="getIcon(optionModel.icon)"
            :class="{selected:  isChecked, disabled: optionModel.disabled}" />
        <div :class="{selected: isChecked, disabled: optionModel.disabled}" style="text-align: center;">
            {{ optionModel.text }}
        </div>
    </span>
</template>

<script>

export default {
    components: {

    },
    data() {
        return {
        }
    },
    props: {
        isChecked: {
            type: Boolean
        },
        optionModel: {
            type: Object
        },
    },
    emits: [
        'select'
    ],
    methods: {
        onClick() {
            if (this.optionModel.disabled) {
                return;
            }
            this.$emit('select', this.optionModel.value); 
        },
        getIcon(fileName) {
            const iconFileName = this.optionModel.disabled
                ? `${fileName}-disabled.svg`
                : this.isChecked
                    ? `${fileName}-selected.svg`
                    : `${fileName}.svg`
            return require(`@/assets/icons/${iconFileName}`);
        }
    },
}
</script>

<style>
.box {
    position: relative;
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    isolation: isolate;

    width: 110px;
    height: 110px;
    background: #FFFFFF;
    border: 1px solid rgba(31, 31, 31, 0.3);
    border-radius: 4px;

    cursor: pointer;
}

.selected {
    color: #3456CE;
    border-color: #3456CE;
}

.disabled {
    color: #4B1F1F1F;
    border-color: #4B1F1F1F;
    cursor: auto;
}

.icon {
    width: 40px;
    height: 40px;
}

.check-icon {
    width: 15px;
    height: 15px;
    position: absolute;
    right: 5px;
    top: 5px;
}
</style>