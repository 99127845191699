<template>
  <b-container>
    <b-row>
      <b-col>
        <h4>Preview</h4>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col class="mb-2" cols="12">
        <h5>BASIC INFORMATION</h5>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col cols="3">
        <div>Event Title</div>
        <div class="mt-1"><b>{{ event.name }}</b></div>
      </b-col>
      <b-col cols="3">
        <div>Location</div>
        <div class="mt-1"><b>{{ event.location }}</b></div>
      </b-col>
      <b-col cols="3">
        <div>Project</div>
        <div class="mt-1"><b>{{ projectName }}</b></div>
      </b-col>
      <b-col cols="3">
        <div>Exclusive To</div>
        <div class="mt-1">
          <b>{{ displayTiers }}</b>
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col cols="3">
        <div>Source Channel</div>
        <div class="mt-1"><b>{{ sourceChannelName }}</b></div>
      </b-col>
      <b-col cols="3">
        <div>Channel Country</div>
        <div class="mt-1"><b>{{ event.channel_country }}</b></div>
      </b-col>
      <b-col cols="3">
        <div>Category</div>
        <div class="mt-1"><b>{{ event.category }}</b></div>
      </b-col>
      <b-col cols="3">
        <div>Event Tagging</div>
        <div class="mt-1"><b>{{ event.tag }}</b></div>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col cols="3">
        <div>Event Date</div>
        <div class="mt-1"><b>{{ displayDate }}</b></div>
      </b-col>
      <b-col cols="3">
        <div>Start Time</div>
        <div class="mt-1"><b>{{ displayStartTime }}</b></div>
      </b-col>
      <b-col cols="3">
        <div>End Time</div>
        <div class="mt-1"><b>{{ displayEndTime }}</b></div>
      </b-col>
      <b-col cols="3">
        <div>Event Type</div>
        <div class="mt-1"><b>{{ event.type }}</b></div>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col cols="3">
        <div>Image Upload</div>
        <div class="mt-1">
          <img width="100" height="100" :src="imageUrl" />
        </div>
      </b-col>
    </b-row>
    <b-row v-if="hasGuests" class="mt-3">
      <b-col cols="6">
        <div>Guests</div>
        <div class="mt-1"><b>{{ displayGuests }}</b></div>
      </b-col>
    </b-row>

    <hr class="my-3" />

    <b-row class="mt-2">
      <b-col class="mb-2" cols="12">
        <h5>SETTINGS</h5>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col cols="3">
        <div>Budget</div>
        <div class="mt-1"><b>RM {{ displayBudget }}</b></div>
      </b-col>
      <b-col cols="3">
        <div>Maximum Pax</div>
        <div class="mt-1"><b>{{ event.no_of_pax }}</b></div>
      </b-col>
      <b-col cols="3">
        <div>Open for Registration</div>
        <div class="mt-1"><b>{{ displayBoolean(event.is_registrable) }}</b></div>
      </b-col>
      <b-col cols="3">
        <div>Conduct Survey after Event</div>
        <div class="mt-1"><b>{{ displayBoolean(event.has_survey) }}</b></div>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col cols="3">
        <div>Event Happen at Sales Gallery</div>
        <div class="mt-1"><b>{{ displayBoolean(event.at_sales_gallery) }}</b></div>
      </b-col>
      <b-col cols="3">
        <div>Display at KORA App</div>
        <div class="mt-1"><b>{{ displayBoolean(event.is_public) }}</b></div>
      </b-col>
      <b-col cols="3">
        <div>Display at Home Page</div>
        <div class="mt-1"><b>{{ displayBoolean(event.is_featured) }}</b></div>
      </b-col>
      <b-col cols="3">
        <div>EDM</div>
        <div class="mt-1">
          <span class="edm-image" v-for="edm in edms" :key="edm">
            <img width="100" height="100" :src="edm" />
          </span>
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col cols="12">
        <div>Short Description</div>
        <div class="mt-1"><b>{{ event.short_description }}</b></div>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col cols="12">
        <div>Long Description</div>
        <div class="mt-1" v-html="event.description"></div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { BContainer, BRow, BCol, BImg } from 'bootstrap-vue';
import moment from 'moment';

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BImg
  },
  props: {
    event: {
      type: Object
    },
    sourceChannels: {
      type: Array
    },
    projects: {
      type: Array
    },
  },
  computed: {
    displayBudget() {
      return this.event.budget.toLocaleString();
    },
    displayDate() {
      return this.event.start_date ? moment(this.event.start_date, 'yyyy-MM-DD').format('DD MMM yyyy') : '-';
    },
    displayStartTime() {
      return this.event.start_date ? moment(this.event.start_date, "yyyy-MM-DD HH:mm").format('hh:mm a') : '-';
    },
    displayEndTime() {
      return this.event.end_date ? moment(this.event.end_date, "yyyy-MM-DD HH:mm").format('hh:mm a') : '-';
    },
    imageUrl() {
      if (this.event.thumbnail) {
        return URL.createObjectURL(this.event.thumbnail);
      } else if (this.event.thumbnailUrl) {
        return this.event.thumbnailUrl;
      }
    },
    sourceChannelName() {
      let data = this.sourceChannels.find(item => item.id === this.event.source_channel_id)
      return data ? data.name : null
    },
    projectName() {
      let data = this.projects.find(item => item.id === this.event.project_id)
      return data ? data.name : null
    },
    displayTiers() {
      return this.event.exclusiveTiers.map(tier => tier.title).join(', ');
    },
    displayGuests() {
      return this.event.invitees ? this.event.invitees.map(invitee => invitee.name).join(', ') : '-';
    },
    hasGuests() {
      return this.event.invitees && this.event.invitees.length > 0;
    },
    edms() {
      var edmList = [];
      if (this.edmEnglish) {
        edmList.push(this.edmEnglish);
      }
      if (this.edmSimplifiedChinese) {
        edmList.push(this.edmSimplifiedChinese);
      }
      if (this.edmTraditionalChinese) {
        edmList.push(this.edmTraditionalChinese);
      }
      return edmList;
    },
    edmEnglish() {
      if (this.event.edm_english) {
        return URL.createObjectURL(this.event.edm_english);
      } else if (this.event.edmEnglishUrl) {
        return this.event.edmEnglishUrl;
      }
    },
    edmSimplifiedChinese() {
      if (this.event.edm_chinese_simplified) {
        return URL.createObjectURL(this.event.edm_chinese_simplified);
      } else if (this.event.edmChineseSimplifiedUrl) {
        return this.event.edmChineseSimplifiedUrl;
      }
    },
    edmTraditionalChinese() {
      if (this.event.edm_chinese_traditional) {
        return URL.createObjectURL(this.event.edm_chinese_traditional);
      } else if (this.event.edmChineseTraditionalUrl) {
        return this.event.edmChineseTraditionalUrl;
      }
    },
  },
  methods: {
    displayBoolean(value) {
      return value ? "Yes" : "No"
    },
  }
}
</script>

<style>
.edm-image {
  display: inline-block;
  margin-right: 1rem;
  margin-bottom: 1rem;
}
</style>