var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"basicInfoRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',[_c('h4',[_vm._v("Basic Information")])])],1),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',[_vm._v("GENERAL")])]),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Event Title","label-for":"eventTitle"}},[_c('validation-provider',{attrs:{"name":"Event Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"eventTitle","placeholder":"Enter Event Title","state":errors.length > 0 ? false : null},model:{value:(_vm.eventTitle),callback:function ($$v) {_vm.eventTitle=$$v},expression:"eventTitle"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Location","label-for":"location"}},[_c('validation-provider',{attrs:{"name":"Location","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"location","placeholder":"Enter Location","state":errors.length > 0 ? false : null},model:{value:(_vm.location),callback:function ($$v) {_vm.location=$$v},expression:"location"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Project","label-for":"project"}},[_c('validation-provider',{attrs:{"name":"Project","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.projectOptions,"value-field":"id","text-field":"name","state":errors.length > 0 ? false : null},model:{value:(_vm.selectedProject),callback:function ($$v) {_vm.selectedProject=$$v},expression:"selectedProject"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Source Channel","label-for":"sourceChannel"}},[_c('validation-provider',{attrs:{"name":"Source Channel","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.sourceOptions,"value-field":"id","text-field":"name","state":errors.length > 0 ? false : null},model:{value:(_vm.selectedSource),callback:function ($$v) {_vm.selectedSource=$$v},expression:"selectedSource"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Channel Country","label-for":"country"}},[_c('validation-provider',{attrs:{"name":"Country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.countryOptions,"state":errors.length > 0 ? false : null},model:{value:(_vm.selectedCountry),callback:function ($$v) {_vm.selectedCountry=$$v},expression:"selectedCountry"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Exclusive To","label-for":"exclusiveTo"}},[_c('validation-provider',{attrs:{"name":"Exclusive To","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-select',{attrs:{"options":_vm.tierOptions,"multiple":"","state":errors.length > 0 ? false : null,"label":"title"},model:{value:(_vm.selectedTiers),callback:function ($$v) {_vm.selectedTiers=$$v},expression:"selectedTiers"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('hr',{staticClass:"my-2"}),_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',{attrs:{"cols":"12"}},[_c('h5',[_vm._v("IMAGE UPLOAD")])]),_c('b-col',{staticClass:"grey-container p-1 my-1",attrs:{"cols":"12"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"3"}},[_c('div',{attrs:{"id":"preview"}},[(_vm.imageUrl)?_c('img',{attrs:{"width":"200","height":"200","src":_vm.imageUrl}}):_vm._e()])]),_c('b-col',{staticClass:"ml-1",attrs:{"cols":"7"}},[_c('div',{staticClass:"mb-1"},[_vm._v("Required image resolution 1000x1000, image size 10MB.")]),_c('validation-provider',{attrs:{"name":"Image","rules":_vm.imageRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"value":_vm.thumbnail,"placeholder":"Choose File","drop-placeholder":"Drop file here...","state":errors.length > 0 ? false : null,"accept":"image/*"},on:{"change":_vm.onImageChange}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('hr',{staticClass:"my-2"}),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',[_vm._v("EVENT")])]),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Event Date","label-for":"eventDate"}},[_c('b-form-datepicker',{attrs:{"date-format-options":{ year: 'numeric', month: 'short', day: '2-digit' },"locale":"en-GB"},model:{value:(_vm.eventDate),callback:function ($$v) {_vm.eventDate=$$v},expression:"eventDate"}})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Start Time","label-for":"startTime"}},[_c('b-form-timepicker',{attrs:{"hour12":"","hourCycle":"h12","locale":"en"},model:{value:(_vm.startTime),callback:function ($$v) {_vm.startTime=$$v},expression:"startTime"}})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Event Tagging","label-for":"eventTagging"}},[_c('validation-provider',{attrs:{"name":"Event Tagging","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.eventTagOptions,"state":errors.length > 0 ? false : null},model:{value:(_vm.eventTag),callback:function ($$v) {_vm.eventTag=$$v},expression:"eventTag"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"End Time","label-for":"endTime"}},[_c('b-form-timepicker',{attrs:{"hour12":"","hourCycle":"h12","locale":"en"},model:{value:(_vm.endTime),callback:function ($$v) {_vm.endTime=$$v},expression:"endTime"}})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Event Type","label-for":"eventType"}},[_c('validation-provider',{attrs:{"name":"Event Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('box-radio-button',{attrs:{"options":_vm.eventTypeOptions,"value":_vm.selectedEventType},on:{"select":function (value) { return _vm.selectedEventType = value; }}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Category","label-for":"category"}},[_c('validation-provider',{attrs:{"name":"Category","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('box-radio-button',{attrs:{"value":_vm.selectedCategory,"options":_vm.eventCategoryOptions},on:{"select":function (value) { return _vm.selectedCategory = value; }}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),(_vm.isPrivate)?_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Select KORA member","label-for":"guests"}},[_c('vue-select',{attrs:{"options":_vm.referrerOptions,"multiple":"","label":"name"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var name = ref.name;
var phone_number = ref.phone_number;
return [_vm._v(" "+_vm._s(name)+" ("+_vm._s(phone_number)+") ")]}},{key:"selected-option",fn:function(ref){
var name = ref.name;
var phone_number = ref.phone_number;
return [_vm._v(" "+_vm._s(name)+" ("+_vm._s(phone_number)+") ")]}}],null,false,2712286349),model:{value:(_vm.privateGuests),callback:function ($$v) {_vm.privateGuests=$$v},expression:"privateGuests"}})],1)],1)],1):_vm._e(),(_vm.isGeneralRegistration)?_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',{staticClass:"grey-container p-2 my-1",attrs:{"cols":"12"}},[_c('b-row',{staticClass:"mx-2"},[_c('b-col',{attrs:{"cols":"12"}},[_c('h5',[_vm._v(" Form's Data Field Configuration ")])])],1),(_vm.dynamicFields.length > 0)?_c('draggable',{staticClass:"list-group list-group-flush cursor-move mt-2",attrs:{"tag":"ul"},model:{value:(_vm.dynamicFields),callback:function ($$v) {_vm.dynamicFields=$$v},expression:"dynamicFields"}},_vm._l((_vm.dynamicFields),function(field,index){return _c('DynamicFormField',{key:field.id,attrs:{"fieldOptions":_vm.dynamicFieldOptions,"selectedOption":field.selectedOption,"isRequired":field.required},on:{"required":function (required) { return _vm.onFieldRequiredChange(field, required); },"remove":function($event){return _vm.onFieldRemove(index)},"change":function (value) { return _vm.onFieldChange(field, value); }}})}),1):_vm._e(),_c('b-row',{staticClass:"mx-2"},[_c('b-col',{staticClass:"mt-2",attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.onFieldAdd}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon"}}),_c('span',[_vm._v("Add Data Field")])],1)],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }